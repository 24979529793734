import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import Layout from '../components/layout';
import SEO from '../components/seo';
import TwitterFollowButton from '../components/bio';
import styles from '../stylesheets/article.module.css';
import '../stylesheets/gist.css';

const ArticlePostTemplate = props => {
  // Lazy load the component so it will only render on the client side
  const ShareButton = React.lazy(() => import('../components/share-button'));

  const post = props.data.markdownRemark;
  const date = post.frontmatter.date || false;
  const tags = post.frontmatter.tags || false;
  const { ogimage } = post.frontmatter;
  const ogImagePath = ogimage && ogimage.childImageSharp.fixed.src;
  const isSSR = typeof window === 'undefined';
  const readingTime = post.fields.readingTime.text;
  const article = post.parent.sourceInstanceName === 'articles' ? true : false;

  return (
    <Layout>
      <Helmet>
        <link rel="preconnect" href="https://widgets.flickr.com" crossorigin />
      </Helmet>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={ogImagePath}
        keywords={tags}
      />
      <article>
        {article && <div className={styles.readingTime}>{readingTime}</div>}

        <h1 className={styles.largetitle}>{post.frontmatter.title}</h1>
        <p className={styles.byline}>{post.frontmatter.description}</p>

        <div className="cms" dangerouslySetInnerHTML={{ __html: post.html }} />

        {article && !isSSR && navigator.share && (
          <React.Suspense fallback={<div />}>
            <ShareButton text="Share this article" />
          </React.Suspense>
        )}

        {date && article && (
          <div className={styles.meta}>
            <span>
              Posted{' '}
              <time className="article__date">{post.frontmatter.date}</time>
            </span>

            <TwitterFollowButton />
          </div>
        )}
      </article>
    </Layout>
  );
};

export default ArticlePostTemplate;

export const pageQuery = graphql`
  query ArticleBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      excerpt(pruneLength: 160)
      html
      fields {
        readingTime {
          text
        }
      }
      id
      parent {
        ... on File {
          sourceInstanceName
        }
      }
      frontmatter {
        tags
        title
        date(formatString: "MMMM DD, YYYY")
        description
        ogimage {
          childImageSharp {
            fixed {
              src
            }
          }
        }
      }
    }
  }
`;
