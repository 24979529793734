import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Follow } from 'react-twitter-widgets';
import styles from '../stylesheets/bio.module.css';

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={(data) => {
        const { social } = data.site.siteMetadata;
        return (
          <div className={styles.button}>
            <Follow
              username={social.twitter}
              options={{
                size: 'large',
                count: 'none',
              }}
            ></Follow>
          </div>
        );
      }}
    />
  );
}

const bioQuery = graphql`
  query BioQuery {
    site {
      siteMetadata {
        social {
          twitter
        }
      }
    }
  }
`;

export default Bio;
